import { Shop } from './shop.model';

export class SearchResultsResponse {
    shops: Shop[];
    zipCode: string;
    radius: string;
    displayShopsNotFound?: boolean;
    displayCertShopsNotFound?: boolean;
    isCertShopSearch?: boolean;
    displayIIOMessage?: boolean;
    searchTerm?: string;
    searchRadius?: string;
    isRadiusExpanded?: boolean;
    isZipCodeSearch?: boolean;
    isNameSearch?: boolean;
    makeValue?: string;
    isOtherCountrySearch?: boolean;
}
