<div #searchComponent>
  <div [ngClass]="BLandingPage ? 'optional-color': ''" *ngIf="!searchResultsPage" class="-oneX-body--tertiary -oneX-typography-variant1 optional">
    *Optional
  </div>
  <div *ngIf="!searchResultsPage">
    <div class="-oneX">
      <div class="vehicle-make-dropdown">
        <label for="name" class="-oneX-dropdown__floatingLabel" [ngClass]="{'-oneX--floatUp': makeValue}">Vehicle
          Make*</label>
        <select (change)="selectVehicleMake($event.target.value)" [(ngModel)]="makeValue" id="vehicleMakeDropdown"
          type="text" class="-oneX-dropdown">
          <option *ngFor="let vehicleMake of vehicleMakeOptions" [value]="vehicleMake">{{vehicleMake}}</option>
        </select>
      </div>
    </div>
  </div>

<form 
  [ngClass]="searchResultsPage ? 'search-results-search-form' : 'search-form'"
  [formGroup]='searchForm' 
  (ngSubmit)="submitFromForm()" 
  #myform="ngForm">
  <div class="search-components-container">
    <div 
      [ngClass]="searchResultsPage ? '-oneX search-results-input-box' : '-oneX input-box'">
      <fieldset class="-oneX-fieldset">
        <label 
          for="address" 
          class="-oneX-textfield__floatingLabel" 
          [ngClass]="{
          '-oneX--floatUp': searchHasFocus || (!searchHasFocus && !isSearchInputEmpty),
          'shop-label-focus-color': searchHasFocus,
          'label-position-with-reset': isPanMoved
        }">
          {{searchBarText}}
        </label>
        <input #search 
          placeholder="" 
          id="address" 
          value="" 
          type="text"
          class="-oneX-textfield--floating-input -oneX-textfield--control" 
          aria-describedby="address"
          autocapitalize="off" 
          spellcheck="off" 
          formControlName="address"
          (focus)="searchHasFocus = true; maintainedFocus = false;" 
          (blur)="searchHasFocus = false"
          (input)="maintainedFocus = true; isSearchInputEmpty = onTextInput($event)"
          [(ngModel)]="searchTerm"
          [attr.disabled]="(maintenance$ | async)?.maintenanceOutage_during_flag ? true : null">
        <button 
          *ngIf="!isSearching && (maintainedFocus || (!maintainedFocus && (isSearchInputEmpty)))"
          class="-oneX-btn-control" 
          id="search-button" 
          type="submit" 
          aria-label="Submit"
          [attr.disabled]="(maintenance$ | async)?.maintenanceOutage_during_flag ? true : null">
          <div 
            aria-label="search" 
            role="img" 
            class="-oneX-icon--controls--search"
            [ngClass]="{'searching-icon': isSearching}">
          </div>
        </button>
        <button 
          *ngIf="!isSearching && !maintainedFocus && !isSearchInputEmpty" 
          id="clear-button"
          class="-oneX-btn-control" 
          (click)="clearSearch()" 
          aria-label="Clear field">
          <div 
            aria-label="clear" 
            role="img" 
            [ngClass]="{
              'clear': !isSearching,
              'searching-icon': isSearching
            }">
          </div>
        </button>
        <button 
          *ngIf="isSearching" 
          class="-oneX-btn-control" 
          id="searching-button" 
          aria-label="Submitting">
          <div 
            aria-label="searching" 
            role="img" 
            [ngClass]="{
              'searching-icon': isSearching
            }">
          </div>
        </button>
      </fieldset>
    </div>
    <div class="select-radius">
      <select 
        id="radius" 
        aria-label="radius" 
        type="text" 
        class="-oneX-dropdown" 
        formControlName="radius" 
        name="radius"
        (change)="searchIfRadiusChanged()"
        [attr.disabled]="(maintenance$ | async)?.maintenanceOutage_during_flag ? true : null">
        <option 
        *ngFor="let label of radiusLabels" 
        [ngValue]="label">
          {{label}}
        </option>
      </select>
    </div>
  </div>
  
    <div
      *ngIf="((searchForm.controls['address'].touched || myform.submitted) && (searchForm.controls['address'].hasError('required') || searchForm.controls['address'].hasError('notValidLocation')))"
      id="name-err" 
      class="-oneX-widget__err-text" 
      aria-live="assertive" role="alert">
        Please enter a valid location and select a radius
    </div>

</form>

<div *ngIf="displayCertShopsNotFound" class="-oneX-widget__err-text cert-error">
  <div *ngIf="!isOtherCountrySearch">
    We've expanded your search to 75 miles, but couldn't find any shops that match your criteria. Please try refining your search and try again.
  </div>
  <div *ngIf="isOtherCountrySearch">
    This service is only available in the U.S. Change your search criteria and try again, use our online chat feature, or contact your claim handler.
  </div>
</div>
</div>