<div class="-oneX-container -oneX">

  <!-- Shop Search -->
  <div class="-oneX-row-fluid">
    <!-- Title -->
    <div class="header center -oneX-offset-xs-1 -oneX-col-xs-10 -oneX-offset-sm-1 -oneX-col-sm-10 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <div role="heading" aria-level="1" class="-oneX-heading--h3 -oneX-font--semi">
        Sorry. No Select Service<sup>®</sup> shops were found.
      </div>
    </div>
    <!-- Subtitle -->
    <div class="subtitle center -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">
      <div *ngIf="!isOtherCountrySearch" class="-oneX-body--primary">
        We expanded your search to 50 miles, but didn’t find any Select Service shops.
        Change your search criteria or try another {{(complianceVerbiage$ | async)}}  option below.
      </div>
      <div *ngIf="isOtherCountrySearch" class="-oneX-body--primary">
        This service is only available in the U.S. Change your search criteria and try again, use our online chat
        feature, or contact your claim handler.
      </div>
    </div>
    <!-- Search Bar -->
    <div class="search-bar">
      <app-search [currentPage]="currentPage" [isViewStatic]="isViewStatic" (changetoResultView)="changetoResultView($event)">
      </app-search>
      <br><br>
    </div>
  </div>

  <!-- Estimate Options -->
  <div *ngIf="!isOtherCountrySearch" class="center no-padding -oneX-row-fluid">

    <div class="no-padding -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <!-- Title -->
      <div class="-oneX-heading--h4 estimate-options-header">Other {{(complianceVerbiage$ | async)}} options</div>

      <!-- Subtitles -->
      <div *ngIf="minorDamageScreen || majorDamageScreen" class="dmg-screen-subtitle">
        <div *ngIf="minorDamageScreen" class="pe-subtitle -oneX-body--primary">Your vehicle has minor exterior damage, so we recommend using Photo Estimate. If additional damage is found, we'll work directly with your shop to approve and pay for those repairs.</div>
        <div *ngIf="majorDamageScreen" class="ea-subtitle -oneX-body--primary">Your damage is more than minor. To get an accurate {{(complianceVerbiage$ | async)}}, we recommend using an Estimate Assist shop.</div>
      </div>
      <hr *ngIf="minorDamageScreen" class="pe-hr -oneX-hr">
      <hr *ngIf="majorDamageScreen" class="ea-hr -oneX-hr">
    </div>

    <!-- Vehicle Damage -->
    <div *ngIf="damageQuestionsScreen" class="-oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-2 -oneX-col-md-8 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6">
      <div class="vehicle-damage-container">
        <div class="vehicle-damage-subtitle -oneX-body--primary">Tell us about your vehicle damage and we’ll help find the right {{(complianceVerbiage$ | async)}} option for you.</div>
        <div class="vehicle-damage-question -oneX-body--primary">Does your vehicle have any of the damages below?</div>
        <!-- Vehicle Damage Details -->
        <ul>
          <li class="vehicle-damage-detail -oneX-body--secondary">Leaking fluid</li>
          <li class="vehicle-damage-detail -oneX-body--secondary">Damage under the vehicle</li>
          <li class="vehicle-damage-detail -oneX-body--secondary">Doors, hood or trunk no longer open and close freely</li>
        </ul>
        <!-- Vehicle Damage Buttons -->
        <div class="vehicle-damage-btn-container">
          <span>
            <!-- ID added for data tagging - do not change without contacting Data Capture  -->
            <button id="veh-dmg-yes-btn" (click)="changeScreen('yes')"
                    class="vehicle-damage-btn -oneX-btn-auto-advance-selector">Yes
            </button>
            <!-- ID added for data tagging - do not change without contacting Data Capture  -->
            <button id="veh-dmg-no-btn" (click)="changeScreen('no')"
                    class="vehicle-damage-btn -oneX-btn-auto-advance-selector">No
            </button>
          </span>
        </div>
      </div>
    </div>

    <!-- Photo Estimate & Estimate Assist -->

    <!-- Photo Estimate -->
    <div *ngIf="minorDamageScreen" class="outer-pe-container no-padding -oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8">
      <div class="dmg-container minor-dmg">
        <div class="-oneX-eyebrow">Recommended</div>
        <div class="-oneX-heading--h4">Photo Estimate</div>
        <div class="pe-container">
          <!-- Photo Estimate Video & Transcript -->
          <div class="dmg-container minor-dmg">
            <iframe src="https://www.youtube-nocookie.com/embed/TwrB370ycjk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <!-- ID added for data tagging - do not change without contacting Data Capture  -->
            <button id="pe-transcript" class="learn-more-links -oneX-link--block -oneX-link-secondary"
                    data-onex-opensdrawer="pe-transcript-drawer"
                    onclick="oneX.Drawer.showDrawer(this.id)"
                    role="button">Transcript</button>
          </div>
          <!-- Photo Estimate Description & Button -->
          <div class="dmg-container minor-dmg">
            <div class="pe-desc -oneX-body--primary">Take guided photos with your mobile device, get an estimate and payout within 48 hours and handle repairs however you'd like.</div>
            <div class="pe-btn-container">
              <button id="choose-photo-est" 
              class="-oneX-btn-primary"
              (keydown.enter)="routeToPhotoEstimate()"
              (click)="routeToPhotoEstimate()">Choose Photo Estimate
              </button>

              <!-- PE Learn More -->
              <button id="learn-more-pe"
              class="learn-more-links -oneX-link--inline"
              data-onex-opensdrawer="pe-help-drawer"
              onclick="oneX.Drawer.showDrawer(this.id)"
              (click)="setAnalyticsScreenId('pca-faq')"
              (focus)="isDrawerOpen ? setBackScreenId() : null"
              role="link">Learn More
              </button>

            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Estimate Assist -->
    <div *ngIf="majorDamageScreen || minorDamageScreen" class="no-padding"
         [ngClass]="{'-oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-3 -oneX-col-md-6 -oneX-offset-lg-3 -oneX-col-lg-6 -oneX-offset-xl-3 -oneX-col-xl-6': majorDamageScreen,
                     '-oneX-col-xs-12 -oneX-col-sm-12 -oneX-offset-md-1 -oneX-col-md-10 -oneX-offset-lg-2 -oneX-col-lg-8 -oneX-offset-xl-2 -oneX-col-xl-8': minorDamageScreen}">
      <div class="ea-container" [ngClass]="{'ea-container-min': minorDamageScreen}">
        <div class="estimate-assist-header -oneX-heading--h4">Estimate Assist network</div>
        <div class="estimate-assist-desc -oneX-body--primary">Use a shop{{(inLocationText$ | async)}} but miss out on Select Service benefits.</div>
        <!-- Estimate Assist Details -->
        <ul>
          <li class="estimate-assist-detail -oneX-body--secondary">We help you find a shop nearby</li>
          <li class="estimate-assist-detail -oneX-body--secondary">You're free to choose any listed shop</li>
          <li class="estimate-assist-detail -oneX-body--secondary">We'll pay you directly</li>
        </ul>         
        <!-- Estimate Assist Buttons -->
        <div class="ea-btn-container" [ngClass]="{'major-dmg': majorDamageScreen}">
          <!-- ID added for data tagging - do not change without contacting Data Capture  -->
          <button id="view-shops-est-ast" 
                  [ngClass]="{'-oneX-btn-primary': majorDamageScreen, '-oneX-btn-secondary': minorDamageScreen}"
                  class="est-ast-shops-btn "
                  (keydown.enter)="showEstimateAssistDisclaimer()"
                  (click)="showEstimateAssistDisclaimer()">View Estimate Assist shops
          </button>
          <!-- ID added for data tagging - do not change without contacting Data Capture  -->
          <button id="learn-more-est-ast" 
            class="learn-more-links -oneX-link--block"
            [ngClass]="{'learn-more-min': minorDamageScreen}"
            data-onex-opensdrawer="ea-help-drawer"
            onclick="oneX.Drawer.showDrawer(this.id)"
            (click)="setAnalyticsScreenId('estimate-assist-faq')"
            (focus)="isDrawerOpen ? setBackScreenId() : null"
            role="button">{{ learnMoreText }}
          </button>
        </div>
      </div> 
    </div>

  </div> 

</div>

<!-- Estimate Assist Disclaimer Modal -->
<app-modal #EstimateAssistDisclaimer>
  <div id="ea-modal" class="modal-body">
    <!-- EA Disclaimer Header -->
    <h1 *ngIf="isDeviceMobile" class="ea-disclaimer-header -oneX-header--h1">Using an<br>Estimate Assist shop</h1>
    <h3 *ngIf="!isDeviceMobile" class="ea-disclaimer-header -oneX-header--h3">Using an Estimate Assist shop</h3>
    <!-- EA Disclaimer Body Copy -->
    <div class="ea-disclaimer-body -oneX-body--primary">
      You are free to select repairers who do not have agreements with State Farm. Not all repair facilities charge the same amount
      for vehicle repairs. Any additional payment will be based upon supplemental amounts agreed to by State Farm. You may be responsible
      for any amounts not agreed to by State Farm. This may include, but may not be limited to, storage fees and rental expenses.
    </div>
    <!-- EA Disclaimer Button -->
    <!-- ID added for data tagging - do not change without contacting Data Capture  -->
    <button id="estimate-assist-disclaimer-btn"
            class="ea-disclaimer-btn -oneX-btn-primary"
            (keydown.enter)="routeToEstimateAssistMap()"
            (click)="routeToEstimateAssistMap()"
            [disabled]="disableButton">OK, got it
    </button>
  </div>
</app-modal>

<!-- Photo Estimate Help Drawer -->
<section id="pe-help-drawer" class="-oneX-drawer-container">
  <div class="drawer-container" role="dialog" aria-labelledby="dialogLnkHdr">
    <!-- Title -->
    <h2 class="help-title -oneX-header--h2">Photo estimate</h2>
    <div class="help-body -oneX-body--primary">
      Our online tool allows you to take guided photos of your vehicle damage to get an estimate and payout within 48 hours.
      Once you receive your payment, you decide how you want to handle repairs to your vehicle.
    </div>

    <h3 class="help-header -oneX-header--h3">Am I eligible for photo estimate?</h3>
    <div class="help-body -oneX-body--primary">
      Photo estimate is a great option when your damage is external. Is your vehicle leaking fluid, is there damage underneath
      your vehicle, are your doors, hood, or trunk no longer functioning? If you answered yes, then photo estimate may not
      be the right option for you.
    </div>

    <h3 class="help-header -oneX-header--h3">Additional damages</h3>
    <div class="help-body -oneX-body--primary">
      If you or your shop find additional damages, contact us for review.
    </div>

    <h3 class="help-header -oneX-header--h3">Other estimate options</h3>
    <h6 class="help-sub-header -oneX-header--h6">Select Service shops</h6>
    <div class="help-sub-body -oneX-body--primary">
      State Farm Select Service shops provide quality and reliable one-stop service for estimate and repairs.
      These repair shops have to meet performance standards and offer written national lifetime warranties,
      guaranteed completion dates, vehicle wash and vacuum, and pick-up and delivery upon request.
    </div>

    <h6 class="help-sub-header -oneX-header--h6">Estimate Assist Network</h6>
    <div class="help-sub-body -oneX-body--primary">
      These are repair shops that are part of State Farm’s Estimate Assist Network.
      We will work with these shops directly on the approval of the estimate.
    </div>

    <!-- Close Button -->
    <button class="-oneX-close-X-button" onclick="oneX.Drawer.hideDrawer()" aria-label="Close Drawer">
      <span class="-oneX-icon--close help-close"></span>
    </button>
  </div>
</section>

<!-- EA Help Drawer -->
<section id="ea-help-drawer" class="-oneX-drawer-container">
  <div class="drawer-container" role="dialog" aria-labelledby="dialogLnkHdr">
    <!-- Title -->
    <h2 class="help-title -oneX-header--h2">Estimate Assist Network</h2>
    <div class="help-body -oneX-body--primary">
      These are repair shops that are part of State Farm’s Estimate Assist Network.
      We will work with these shops directly on the approval of the estimate.
      <br><br>
      In your area, we ask that you take your vehicle to the repair facility of your
      choice to obtain an estimate and photos of your vehicle damage. Then, the repair
      facility will send the estimate to State Farm for review and approval.
      <br><br>
      We will notify you and your repair facility once the estimate has been reviewed.
      At that time, please follow up with the repair facility to arrange and authorize your vehicle repairs.
    </div>

    <h3 class="help-header -oneX-header--h3">Why use Estimate Assist?</h3>
    <div class="help-body -oneX-body--primary">
      Choosing an Estimate Assist shop gives you the opportunity to use whatever shop you prefer.
      Using repair facilities in your area that are a part of our Estimate Assist Network could expedite
      the process; the repair facility can submit an estimate and photos directly to State Farm on your behalf.
    </div>

    <h3 class="help-header -oneX-header--h3">Repair costs</h3>
    <div class="help-body -oneX-body--primary">
      Before work begins, there's no need for you to pay anything out of pocket. State Farm will review the estimate
      from the repair facility and once approved, issue payment based on your preference (Direct Deposit or mailed draft).
      <br><br>
      Not all repair facilities outside of our Select Service shops charge the same. Repair estimates can change once work
      begins. If there are additional damages found during repair, you may be responsible for any costs not agreed to by
      State Farm. This may include, but not limited to, storage fees and rental expenses.
    </div>

    <h3 class="help-header -oneX-header--h3">Other estimate options</h3>
    <h6 class="help-sub-header -oneX-header--h6">Photo estimate</h6>
    <div class="help-sub-body -oneX-body--primary">
      Our online tool allows you to take guided photos of your vehicle damage to get an estimate and payout within 48 hours.
      Once you receive your payment, you decide how you want to handle repairs to your vehicle.
    </div>

    <h6 class="help-sub-header -oneX-header--h6">Select Service shops</h6>
    <div class="help-sub-body -oneX-body--primary">
      State Farm Select Service shops provide quality and reliable one-stop service for estimate and repairs.
      These repair shops have to meet performance standards and offer written national lifetime warranties,
      guaranteed completion dates, vehicle wash and vacuum, and pick-up and delivery upon request.
    </div>

    <!-- Close Button -->
    <button class="-oneX-close-X-button" onclick="oneX.Drawer.hideDrawer()" aria-label="Close Drawer">
      <span class="-oneX-icon--close help-close"></span>
    </button>
  </div>
</section>

<!-- Photo Estimate Video Transcript  -->
<section id="pe-transcript-drawer" class="-oneX-drawer-container">
  <div class="drawer-container" role="dialog" aria-labelledby="dialogLnkHdr">
    <!-- Title -->
    <h2 class="help-title -oneX-drawer-header--h2">Photo Estimate</h2>
    <div class="help-body -oneX-body--primary">
      [MUSIC PLAYING]
      <br><br>
      (IMAGE)<br>
      State Farm logo appears on screen.
      Then a photo with a hand holding a cell phone appears.
      A car is blurred in the background.
      "Photo Estimating" appears on screen.
      <br><br>
      (Speaker: VO)<br>
      We want your State Farm auto claim to be as stress-free as possible.
      <br><br>
      (IMAGE)<br>
      Cut to a different hand holding a cell phone horizontally.
      There's a picture of a white car on the phone.
      Photo is taken from the back of the car.
      A white car is blurred in the background.
      The phone is taking a picture of that car.
      <br><br>
      (Speaker: VO)<br>
      That's why we offer photo estimating to help you quickly get your estimate and claim payment.
      <br><br>
      (Image)<br>
      Cut to another different hand holding a phone vertically.
      There's a close-up picture on the phone screen of minor car damage.
      The damage is underneath the car's headlight.
      A close-up of the car damage is in the background.
      The color of the damaged part is blue.
      The blue part is cracked.
      <br><br>
      (Speaker: VO)<br>
      If you choose photo estimating, you'll use an app to take photos of your damage and send them to us.
      Don't worry, the app has easy to follow instructions and will guide you through the process.
      <br><br>
      (Image)<br>
      Cut to an illustrated image of a phone on a khaki oval circle. The phone and khaki circle are on the right.
      The phone is moving from a side to front view while VO speaks.
      The phone has "Estimate Details" on the screen.
      Those details include a date, company name, damage location, etc.
      It also includes an estimate of $1,503.02 and a $500 deductible.
      "View a PDF of your estimate" appears on bottom of phone screen.
      "Initial estimate for the repairs sent to you in one business day" appears on a white background on the left side of the video.
      <br><br>
      (Speaker: VO)<br>
      Once you submit your photos, we'll send you the initial estimate for the repairs within one business day and send the payment directly to you.
      <br><br>
      (Image)<br>
      Cut to a repair man working on fixing body damage to a car.
      His back is to camera and he has protective eyewear and a mask.
      He is using a buffer to smooth the car. The car is white.
      <br><br>
      (Speaker: VO)<br>
      Then, when you're ready to get your vehicle fixed, simply provide the estimate to the repair shop of your choice when you schedule your repairs.
      <br><br>
      (Image)<br>
      Cut to two men facing forward in a repair shop.
      They are smiling and holding a blue clipboard together.
      <br><br>
      (Speaker: VO)<br>
      If the repair shop finds additional damage related to your claim, we'll work directly with them to review the damage and pay the additional amount we owe.
      <br><br>
      (Image)<br>
      Cut to man woman and child in a car. Man is driving, woman is in passenger seat.
      Child is in the back.
      They are all smiling.
      Sun is shining in the background.
      <br><br>
      (Speaker: VO)<br>
      We know having a claim can be stressful.
      Our quick and convenient photo estimating service is another great option from State Farm.
      <br><br>
      (Image)<br>
      State Farm logo appears on screen against red background.
      <br><br>
      (Music playing)
      <br><br>
      (Speaker: VO)<br>
      Like a good neighbor, State Farm is there.
    </div>

    <!-- Close Button -->
    <button class="-oneX-close-X-button" onclick="oneX.Drawer.hideDrawer()" aria-label="Close Drawer">
      <span class="-oneX-icon--close help-close"></span>
    </button>
  </div>
</section>
